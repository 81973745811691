/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li",
    em: "em",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-3-rucksack-reorganization"
  }, "Day 3: Rucksack Reorganization"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/3"
  }, "https://adventofcode.com/2022/day/3")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_03.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "The elves are all wearing backpacks, but they haven’t been packed correctly."), "\n", React.createElement(_components.p, null, "Each line of the input represents the items inside an elf’s backpack.\nEvery item type is identified by a single lowercase or uppercase letter (that is, ", React.createElement(_components.code, null, "a"), " and ", React.createElement(_components.code, null, "A"), " refer to different types of items)."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "vJrwpWtwJgWrhcsFMMfFFhFp\njqHRNqRjqzjGDLGLrsFMfFZSrLrFZsSL\nPmmdzqPrVvPwwTWBwg\nwMqvLMZHhHMvwLHjbvcjnnSBnvTQFn\nttgJtRGJQctTZtZT\nCrZsJsPPZsGzwwsLwLmpwMDw\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Each backpack has 2 equally sized compartments."), "\n", React.createElement(_components.li, null, "Each elf is carrying an even number of items."), "\n", React.createElement(_components.li, null, "The amount of items stored in each compartment is equal."), "\n", React.createElement(_components.li, null, "The first half of the characters represent items in the first compartment, while the second half of the characters represent items in the second compartment."), "\n"), "\n", React.createElement(_components.p, null, "All items of a given type are meant to go into exactly one of the two compartments.\nThe Elf that did the packing failed to follow this rule for exactly one item type per rucksack."), "\n", React.createElement(_components.p, null, "Each item ", React.createElement(_components.em, null, "type"), " has a numeric priority."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Lowercase item types ", React.createElement(_components.code, null, "a"), " through ", React.createElement(_components.code, null, "z"), " have priorities 1 through 26."), "\n", React.createElement(_components.li, null, "Uppercase item types ", React.createElement(_components.code, null, "A"), " through ", React.createElement(_components.code, null, "Z"), " have priorities 27 through 52."), "\n"), "\n", React.createElement(_components.p, null, "The question asks to find the item that was mispacked for each elf, and to sum the priorities of those items."), "\n", React.createElement(_components.h3, {
    id: "implementation"
  }, "Implementation"), "\n", React.createElement(_components.p, null, "I looped through every line of the input, mapped each line to a score, and summed those up."), "\n", React.createElement(_components.p, null, "I started off with this pseudocode that I then filled in:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "input\n    .lines()\n    .map(/* map a line to a priority score */)\n    .sum()\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "I decided to convert every line to bytes, because that made the types a bit easier.\nThis does not change the steps to solve this.\nThey remain the same in every language.")), "\n", React.createElement(_components.p, null, "For each line, split it in half to get the left and right compartment."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let (left, right) = line.split_at(line.len() / 2);\n")), "\n", React.createElement(_components.p, null, "Loop through the left compartment and find the first (and only) item that also exists in the right compartment."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "left.iter()\n    .find(|item| right.contains(item))\n")), "\n", React.createElement(_components.p, null, "With the mispacked item found, all that is left is converting it to a priority.\nAs with day2, I’ll use the fact that for both ", React.createElement(_components.code, null, "a"), " to ", React.createElement(_components.code, null, "z"), " and ", React.createElement(_components.code, null, "A"), " to ", React.createElement(_components.code, null, "Z"), " every value in that sequence has an incrementing ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/ASCII"
  }, "ASCII"), " value."), "\n", React.createElement(_components.p, null, "That means I can map ", React.createElement(_components.code, null, "a"), " to ", React.createElement(_components.code, null, "z"), " into 0 to 25."), "\n", React.createElement(_components.p, null, "Adding 1 each time to get to the numbers the question gives us."), "\n", React.createElement(_components.p, null, "Same thing for ", React.createElement(_components.code, null, "A"), " to ", React.createElement(_components.code, null, "Z"), ", but instead of adding 1, I add 27."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "match item {\n    b'a'..=b'z' => (item - b'a') + 1,\n    b'A'..=b'Z' => (item - b'A') + 1 + 26,\n    _ => panic!(\"Only a-z and A-Z allowed\")\n}\n")), "\n", React.createElement(_components.h3, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs"
  }, "pub fn part_1() -> u32 {\n    let input = std::fs::read_to_string(\"src/day03.txt\").unwrap();\n\n    input\n        .lines()\n        // calculate the priority of the single unique item for each line\n        .filter_map(|line| {\n            let line = line.as_bytes();\n            let (left, right) = line.split_at(line.len() / 2);\n\n            left.iter()\n                .find(|item| right.contains(item))\n                .map(|item| match item {\n                    b'a'..=b'z' => (item - b'a') + 1,\n                    _ => (item - b'A') + 1 + 26,\n                } as u32)\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The elves are grouped per 3.\nFor each group, there is a single item type that all 3 elves carry."), "\n", React.createElement(_components.p, null, "Every set of three lines in your input corresponds to a single group."), "\n", React.createElement(_components.p, null, "The question asks to find the common item in each group, and to sum the priorities of those items."), "\n", React.createElement(_components.h3, {
    id: "implementation-1"
  }, "Implementation"), "\n", React.createElement(_components.p, null, "I looped through groups of 3 lines of the input, mapped each group to a score, and summed those up."), "\n", React.createElement(_components.p, null, "I started off with this pseudocode that I then filled in:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "input\n    .lines()\n    .group_per_3(/* map a group to a priority score */)\n    .map()\n    .sum()\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "I decided to convert every line to bytes, because that made the types a bit easier.\nThis does not change the steps to solve this.\nThey remain the same in every language.")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.rs/itertools/latest/itertools/"
  }, "Itertools"), " has a neat function to group items in an iterator."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.rs/itertools/latest/itertools/trait.Itertools.html#method.tuples"
  }, React.createElement(_components.code, null, "tuples()")), " allowed me to look at 3 items in the ", React.createElement(_components.code, null, "lines()"), " iterator at a time."), "\n", React.createElement(_components.p, null, "The pseudocode turned into:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs"
  }, "use itertools::Itertools;\n\ninput\n    .lines()\n    .tuples()\n    .map(|(sack1, sack2, sack3)| { /* map a group to a priority score */ })\n    .sum()\n")), "\n", React.createElement(_components.p, null, "Loop through the first sack and find the first (and only) item that exists in both other sacks."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "sack1\n    .iter()\n    .find(|item| sack2.contains(item) && sack3.contains(item))\n")), "\n", React.createElement(_components.p, null, "Identical to part1, turn the item you found into a priority score."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "match item {\n    b'a'..=b'z' => (item - b'a') + 1,\n    b'A'..=b'Z' => (item - b'A') + 1 + 26,\n    _ => panic!(\"Only a-z and A-Z allowed\")\n}\n")), "\n", React.createElement(_components.h3, {
    id: "final-code-1"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_03.rs"
  }, "use itertools::Itertools;\n\npub fn part_2() -> u32 {\n    let input = std::fs::read_to_string(\"src/day03.txt\").unwrap();\n\n    input\n        .lines()\n        .map(|line| line.as_bytes())\n        .tuples()\n        .filter_map(|(sack1, sack2, sack3)| {\n            sack1\n                .iter()\n                .find(|item| sack2.contains(item) && sack3.contains(item))\n                .map(|item| match item {\n                    b'a'..=b'z' => (item - b'a') + 1,\n                    _ => (item - b'A') + 1 + 26,\n                } as u32)\n        })\n        .sum()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
